function openMobileNav() {
  const button = document.querySelector('#js-navigation-toggler'),
        el = document.querySelector('#js-navigation-collapse'),
        menuList = document.querySelector('#js-menu-list');
  button.addEventListener('click', () => {
    el.classList.add('is-visible');
    setTimeout(() => {
      el.classList.add('slide-enter');
      button.setAttribute('aria-expanded', 'true');
      menuList.classList.add('fade-enter');
    }, 1);
    setTimeout(() => {
      menuList.classList.remove('fade-enter');
      menuList.classList.add('is-visible');
    }, 401);
  });
}

function closeMobileNav() {
  const button = document.querySelector('#js-navigation-cancel'),
        el = document.querySelector('#js-navigation-collapse'),
        hamburgerButton = document.querySelector('#js-navigation-toggler'),
        dropdownItems = document.querySelectorAll('.navigation__menu-item.dropdown'),
        menuList = document.querySelector('#js-menu-list');

  button.addEventListener('click', () => {
    el.classList.remove('slide-enter');
    menuList.classList.add('fade-leave');
    hamburgerButton.setAttribute('aria-expanded', 'false');
    setTimeout(() => {
      el.classList.remove('is-visible');
      menuList.classList.remove('fade-leave');
      menuList.classList.remove('is-visible');
    }, 400);

    function removeDelayed(el) {
      setTimeout(() => {
        el.classList.remove('is-visible');
      }, 205);
    }
    for (let i = 0; i < dropdownItems.length; i++) {
      let elements = dropdownItems[i];
      elements.classList.remove('fade');
      elements.setAttribute('aria-expanded', 'false');
      removeDelayed(elements);
    }
  });
}

function setClassNameNavbar() {
  const nav = document.querySelector('#js-navigation');
  if (window.scrollY > 3) {
    nav.classList.add('has-shadow');
  } else {
    nav.classList.remove('has-shadow');
  }
}

function toggleSubNav() {
  const elements = document.querySelectorAll('.navigation__menu-item.dropdown .navigation__menu-link');

  for (let i = 0; i < elements.length; i++) {
    elements[i].addEventListener('click', (e) => {
      const target = e.target,
            parent = target.closest('.dropdown');

      if (parent.classList.contains('is-visible')) {
        target.setAttribute('aria-expanded', 'false');
        parent.classList.remove('fade');
        setTimeout(() => {
          parent.classList.remove('is-visible');
        }, 205);
      } else {
        closeAllSubnav();
        target.setAttribute('aria-expanded', 'true');
        parent.classList.add('is-visible');
        setTimeout(() => {
          parent.classList.add('fade');
        }, 10);
      }
    });
  }
}

function closeAllSubnav() {
  const elements = document.querySelectorAll('.js-nav-dropdown');
  for (let i = 0; i < elements.length; i++) {
    const target = elements[i];
    const parent = target.closest('.dropdown');
    target.setAttribute('aria-expanded', 'false');
    parent.classList.remove('fade');
    parent.classList.remove('is-visible');

  }
}

// Close menus when clicking outside of them
window.addEventListener('click', (event) => {
  const dropBtns = document.querySelectorAll('.js-nav-dropdown');
  const dropBtnsArray = Array.prototype.slice.call(dropBtns);
  if (dropBtnsArray.filter(e => e === event.target.parentNode).length === 0) {
    closeAllSubnav();
  }
});

openMobileNav();
closeMobileNav();
toggleSubNav();
setClassNameNavbar();

export {
  setClassNameNavbar
};

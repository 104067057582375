function toggleDropdownFilters() {
  const dropdownBtns = document.querySelectorAll('.js-filters-dropdown button');

  if (dropdownBtns) {
    for (let i = 0; i < dropdownBtns.length; i++) {
      const buttons = dropdownBtns[i];

      buttons.addEventListener('click', (e) => {
        const target = e.target,
              dropdown = target.nextElementSibling;

        if (dropdown.classList.contains('is-block')) {
          dropdown.classList.remove('fade-in');
          target.setAttribute('aria-expanded', 'false');
          setTimeout(() => {
            dropdown.classList.remove('is-block');
          }, 251);
        } else {
          closeAllDropdownFilters();
          dropdown.classList.add('is-block');
          target.setAttribute('aria-expanded', 'true');
          setTimeout(() => {
            dropdown.classList.add('fade-in');
          }, 1);
        }
      });
    }
  }
}

function closeDropdownFiltersOnChange() {
  const checkboxes = document.querySelectorAll('.js-filters-dropdown input[type=checkbox]');

  if (checkboxes) {
    for (i = 0; i < checkboxes.length; i++) {
      const allBoxes = checkboxes[i];

      allBoxes.addEventListener('change', (e) => {
        const target = e.target;

        if (target.checked) {
          setTimeout(() => {
            closeAllDropdownFilters();
          }, 150);
        }
      });
    }
  }
}

function closeAllDropdownFilters() {
  const dropdownBtns = document.querySelectorAll('.js-filters-dropdown button');

  if (dropdownBtns) {
    for (let i = 0; i < dropdownBtns.length; i++) {
      const target = dropdownBtns[i],
        dropdown = target.nextElementSibling;

      dropdown.classList.remove('fade-in');
      target.setAttribute('aria-expanded', 'false');
      dropdown.classList.remove('is-block');
    }
  }
}

function renderTypeFilter() {
  const jsTypeList = document.getElementById('js-type-list');
  if (jsTypeList !== undefined && jsTypeList !== null) {
    const inputs = jsTypeList.getElementsByTagName('input');
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('click', event => {
        var eventValue = event.target.id;
        if (event.target.checked === true) {
          if (window.location.search !== '') {
            if (window.location.search.indexOf('type=') > -1) {
              var currentValue = window.location.search.substring(window.location.search.indexOf('type=') + 5);
              var newSearch = window.location.search.replace(currentValue, eventValue);
              window.location = window.location.origin + window.location.pathname + newSearch;
            } else {
              window.location = window.location.origin + window.location.pathname + window.location.search + '&type=' + eventValue;
            }
          } else {
            window.location = window.location.href + '?type=' + eventValue;
          }
        } else {
          removeFilter('type', eventValue);
        }
      });
    }
  }
}

function renderTagFilter() {
  const jsTagsList = document.getElementById('js-tags-list');
  if (jsTagsList !== undefined && jsTagsList !== null) {
    const inputs = jsTagsList.getElementsByTagName('input');
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('click', event => {
        var eventValue = encodeURIComponent(event.target.id);
        if (event.target.checked === true) {
          if (window.location.search !== '') {
            var newSearch = '';
            if (window.location.search.indexOf('tag=') > -1) {
              newSearch = window.location.search + ',' + eventValue;
            } else {
              newSearch = window.location.search + '&tag=' + eventValue;
            }
            window.location = window.location.origin + window.location.pathname + newSearch;
          } else {
            window.location = window.location.href + '?tag=' + eventValue;
          }
        } else {
          removeFilter('tag', eventValue);
        }
      });
    }
  }
}

function renderPeriodFilter() {
  const jsPeriodList = document.getElementById('js-period-list');
  if (jsPeriodList !== undefined && jsPeriodList !== null) {
    const inputs = jsPeriodList.getElementsByTagName('input');
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('click', event => {
        var eventValue = encodeURIComponent(event.target.id);
        if (event.target.checked === true) {
          if (window.location.search !== '') {
            var newSearch = '';
            if (window.location.search.indexOf('period=') > -1) {
              newSearch = window.location.search + ',' + eventValue;
            } else {
              newSearch = window.location.search + '&period=' + eventValue;
            }
            window.location = window.location.origin + window.location.pathname + newSearch;
          } else {
            window.location = window.location.href + '?period=' + eventValue;
          }
        } else {
          removeFilter('period', eventValue);
        }
      });
    }
  }
}

function removeFilter(type, value) {
  var currentValue = '';
  var encodedValue = encodeURIComponent(value);
  var allQueryStrings = window.location.search.substring(1).split('&');
  for (let index = 0; index < allQueryStrings.length; index++) {
    const element = allQueryStrings[index];
    if (element.indexOf(type) > -1) {
      currentValue = element.substring(element.indexOf(type + '=') + (type.length + 1));
    }
  }

  var newSearch = '';

  if (currentValue.indexOf(',') > -1) {
    newSearch = window.location.search.replace(encodedValue, '');
  } else {
    newSearch = window.location.search.replace(type + '=' + currentValue, '');
  }

  if (newSearch.endsWith(',')) {
    newSearch = newSearch.replace(',', '');
  }

  if (newSearch.indexOf('=,') > -1) {
    newSearch = newSearch.replace('=,', '=');
  }

  newSearch = newSearch.replace(',,', ',');

  var newUrl = window.location.origin + window.location.pathname + newSearch;
  if (newUrl.endsWith('?')) {
    newUrl = newUrl.replace('?', '');
  }

  if (newUrl.endsWith('&')) {
    newUrl = newUrl.replace('&', '');
  }

  if (newUrl.indexOf('?&') > -1) {
    newUrl = newUrl.replace('?&', '?');
  }

  if (newUrl.indexOf('&&') > -1) {
    newUrl = newUrl.replace('&&', '&');
  }
  window.location = newUrl;
}

function removeFilterButtonClick() {
  const btnsList = document.getElementById('js-remove-filter-buttons-list');

  if (btnsList !== undefined && btnsList !== null) {
    const btns = btnsList.getElementsByTagName('button');

    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener('click', event => {
        var filterValue = encodeURIComponent(event.target.getAttribute('data-filtervalue'));
        var filterType = event.target.getAttribute('data-filtertype');
        removeFilter(filterType, filterValue);
      });
    }
  }
}

function clickOutsideCloseFilters() {
  const dropdownBtns = document.querySelectorAll('.js-filters-dropdown');

  if (dropdownBtns.length > 0) {
    document.addEventListener('click', (e) => {
      let target = e.target;

      for (let i = 0; i < dropdownBtns.length; i++) {
        if (target != dropdownBtns[i] && target.parentNode != dropdownBtns[i]) {
          let lastChild = dropdownBtns[i].lastElementChild,
              firstChild = dropdownBtns[i].firstElementChild;
          lastChild.classList.remove('fade-in');
          firstChild.setAttribute('aria-expanded', 'false');
          lastChild.classList.remove('is-block');
        }
      }
    });
  }
}

toggleDropdownFilters();
closeDropdownFiltersOnChange();
clickOutsideCloseFilters();
renderTypeFilter();
renderTagFilter();
renderPeriodFilter();
removeFilterButtonClick();

// Scroll to element on event page
function goToFormElement() {
    const element = document.getElementById('eventSignInForm'),
    btn = document.getElementById('js-eventformbutton');
    if (element && btn){
        btn.addEventListener('click', () => {
            window.scrollTo(0, (element.getBoundingClientRect().top + window.scrollY) - (window.innerHeight / 5));
        });
    }
}

goToFormElement();
function videoSwitch() {
  // look for all elements with class and add onclick event
  const elements = document.getElementsByClassName('js-btn-video-home');
  for (let i = 0; i < elements.length; i++) {
    elements[i].addEventListener('click', function() {
      switchToVideo(this);
    }, false);
  }
  // replace clicked object with iframe video
  function switchToVideo(e) {
    e.outerHTML = '<iframe src="' + e.getAttribute('data-url') + '" title="' + e.getAttribute('data-title') + '" allowFullScreen="allowFullScreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen"></iframe>';
  }
}

function whySearch() {
  const element = document.getElementById("js-why__search-btn");

  if(element) {
    element.onclick = function () {
      let submitUrl = document.getElementById("js-why__search-btn").getAttribute('data-submiturl').toLowerCase();
      const value = document.getElementById("searchPartner").value;

      if (value && submitUrl) {
        submitUrl = submitUrl + value;
        window.location.href = submitUrl
      }
    };
  }

  const inputSearchWhy = document.getElementById("searchPartner");
  if (inputSearchWhy) {
    inputSearchWhy.addEventListener("keydown", function(e)  {
      if (e.key === "Enter") {
        e.preventDefault();
        let submitUrl = document.getElementById("js-why__search-btn").getAttribute('data-submiturl').toLowerCase();
        const value = document.getElementById("searchPartner").value;
        if (value && submitUrl) {
          submitUrl = submitUrl + value;
          window.location.href = submitUrl;
        }
      }
    });
  } 
}

videoSwitch();
whySearch();

function headerCounters() {
  // Source: https://www.studytonight.com/post/how-to-build-an-animated-counter-with-javascript
  const counters = document.querySelectorAll('.js-count');

  // Change variable to alter how many steps there are
  // The lower the stepDivider, the lower the amount of steps (e.g.: 60/2 = 30, so 2 steps of 30, 60/30 = 2, so 30 steps of two)
  const stepDivider = 100;

  counters.forEach((counter) => {
    const updateCount = () => {
      const target = parseInt(counter.getAttribute('js-data-target'));
      const count = parseInt(counter.innerText);
      const increment = Math.ceil(target / stepDivider);

      if (count < target) {
        counter.innerText = count + increment;
        setTimeout(updateCount, 35); // Determine how fast the counter goes up
      } else {
        counter.innerText = target;
      }
    };
    updateCount();
  });
}

headerCounters();

//used on searchpage
function generalSearch() {
  const element = document.getElementById("js-btn-searchgeneral");
  if (element) {
    element.onclick = function () {
      let submitUrl = document.getElementById("js-btn-searchgeneral").getAttribute('data-submiturl').toLowerCase();
      const value = document.getElementById("js-inp-searchgeneral").value;
      if (value && submitUrl)
      {
        submitUrl = submitUrl + '?q=' + value;
        window.location.href = submitUrl
      }
    };
  }
  
  const inputSearchMain = document.getElementById("js-inp-searchgeneral");
  if (inputSearchMain) {
    inputSearchMain.addEventListener("keyup", function(e)  {
      if (e.key === "Enter") {
        e.preventDefault();
        document.getElementById("js-btn-searchgeneral").click();
      }
    });
  }
  if (document.getElementById("js-inp-searchgeneral")) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const q = urlParams.get('q');
    if (q) {
      document.getElementById("js-inp-searchgeneral").value = q;
    }
  }
}

//used in nav-header
function headerNavSearch() {
  const inputSearchHeader = document.getElementById("search");
  if (inputSearchHeader) {
    inputSearchHeader.addEventListener("keyup", function(e)  {
      if (e.key === "Enter") {
        e.preventDefault();
        document.getElementById("js-navigation__search-btn").click();
      }
    });
  }
}

// used on Partner page
function showMorePublications(btnId, listClass) {
  const btnShowMore = document.getElementById(btnId);
  if (btnShowMore) {
    btnShowMore.addEventListener('click', function(e) {
      const list = document.getElementsByClassName(listClass)[0].getElementsByClassName('related__list')[0];

      if (list) {
        const items = list.getElementsByTagName('li');
        for (let index = 5; index < items.length; index++) {
          const element = items[index];
          element.classList.toggle('related__hidden')
        }
        btnShowMore.innerHTML = btnShowMore.innerHTML.trim().replace(/&nbsp;/g, '') == 'Toon meer' ? 'Toon minder' : 'Toon meer';
      }
    });
  }
}

generalSearch();
//headerNavSearch();
showMorePublications('showMorePublications', 'related__news');
showMorePublications('showMoreEvents', 'related__events');
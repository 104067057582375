import {
  tns
} from '../../../../node_modules/tiny-slider/src/tiny-slider';

function sliderVideos() {
  const elements = document.getElementsByClassName('js-slider-videos');
  if (elements.length > 0) {
    return tns({
      container: '.js-slider-videos',
      items: 1,
      controlsContainer: false,
      controlsPosition: 'bottom',
      navPosition: 'bottom',
      arrowKeys: true,
      speed: 800,
      autoplay: false,
      autoplayButton: false,
      autoplayHoverPause: false
    });
  }
}

sliderVideos();

import {
  setClassNameNavbar
} from './navigation';
import './header';
import './why';
import './filters';
import './umbracoforms';
import './slider';
import './cookie';
import './custom';
import './events';

// Actions on window scroll
window.addEventListener('scroll', () => {
  setClassNameNavbar();
});

// Actions on window resize
window.addEventListener('resize', () => {
});